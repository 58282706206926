.menu-bars input:checked ~ span:nth-child(2){
    background-color: rgb(9, 9, 9);
    transform: rotate(45deg) translate(-1px, -1px);
    width: 23px;
}
.menu-bars input:checked ~ span:nth-child(4){
    background-color: rgb(9, 9, 9);
    transform: rotate(-45deg) translate(-1px,0); 
    width: 23px;
}
.menu-bars input:checked ~ span:nth-child(3){
    opacity: 0;
    transform: scale(0); 
}